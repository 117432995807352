import {graphql, Link} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import * as React from 'react';
import styled from 'styled-components';
import {ContainerLg} from '../components/components';
import Layout from '../components/layout';
import SpeakersTopicSection from '../components/sections/speakers-topic-section';
import {COLORS} from '../style/my-theme';

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: {fields: frontmatter___order, order: ASC}
      filter: {fileAbsolutePath: {regex: "/speakers/2023/"}}
    ) {
      edges {
        node {
          id
          body
          slug
          frontmatter {
            order
            fullName
            title
            company
            talkTitle
            website
            github
            linkedin
            twitter
            published
            imageSrc {
              childImageSharp {
                large: gatsbyImageData(
                  height: 180
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
                small: gatsbyImageData(
                  height: 120
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            previewSrc {
              childImageSharp {
                gatsbyImageData(height: 340, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
      }
    }
  }
`;

const SpeakersPage = ({data}: {data: any}) => {
  const speakers = data.allMdx.edges;

  return (
    <Layout>
      <SpeakersTopicSection speakers={speakers} />
    </Layout>
  );
};
export default SpeakersPage;
